import Vue from 'vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import moment from 'moment'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'https://futang2.easymedicinesr.com/admin'
} else if (process.env.NODE_ENV === 'debug') {
  axios.defaults.baseURL = 'https://futang2.easymedicinesr.com/admin'
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://futang2.easymedicinesr.com/admin'
}

Vue.use(ElementUI)

Vue.use(VueQuillEditor)

// 挂在到Vue实例，后面可通过this调用
Vue.prototype.$http = axios
Vue.prototype.moment = moment

axios.defaults.timeout = 6000
// http request 拦截器
axios.interceptors.request.use(
  config => {
    const user = store.state.userInfo.username
    const token = store.state.userInfo.token
    if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.authorization = token // 请求头加上token
    }
    if (user) { // 判断是否存在token，如果存在的话，则每个http header都加上username
      config.headers.common.username = user // 请求头加上username
    }
    // 开启进度条
    NProgress.start()
    return config
  },
  err => {
    return Promise.reject(err)
  })

// response 拦截器中,  隐藏进度条NProgress.done()
axios.interceptors.response.use(response => {
  // 拦截响应，做统一处理
  if (response.data) {
    switch (response.data.meta.status) {
      case 1001:
        ElementUI.Message.error('用户token验证失败')
        router.replace({
          path: '/login'
        })
        break
    }
  }
  // 关闭进度条
  NProgress.done()
  return response
},
// 接口错误状态处理，也就是说无响应时的处理
error => {
  ElementUI.Message.error(error.message)
  return null // 返回接口返回的错误信息
})

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/') {
    next()
  } else {
    if (!store.state.userInfo.username || !store.state.userInfo.token) {
      ElementUI.Message.error('您还没有登录，请先登录')
      next('/login')
    }
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
